.footer a:not(.trustedshop):hover {
  text-decoration: underline;
}
.footer-link:hover,
.footer-link:hover {
  background-size: 0 0.0625rem !important;
  background-position-x: right !important;
}
/* Header.css */
.header-container {
  position: relative;
  z-index: 600;
  background-color: #e3dbd1;
  color: #25485a;
  width: 100%;
}

.header-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #e3dbd1;
  color: #25485a;
  z-index: 600;
  border-bottom: 1px solid #e3dbd1;
}
/* Add these styles to your existing CSS file or create a new one for the sidebar */

.sidebar {
  position: fixed;
  top: 0;
  left: -250px; /* Initial position off-screen */
  width: 250px;
  background-color: #fff; /* Sidebar background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow */
  transition: left 0.3s ease;
  z-index: 1000;
}

.sidebar.open {
  left: 0; /* Slide in when open */
}

.close-btn {
  font-size: 27px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 5px;
}
/* Add these styles to your existing CSS file or create a new one for the sidebar */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: none;
  z-index: 0;
}

.overlay-open {
  display: block; /* Show the overlay when the sidebar is open */
}
