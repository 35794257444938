.benefits-icons .container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 5px;
}
.policy-content-section .owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  color: #fff !important;
  background: #2B403B !important;
  border-radius: 10px !important;
  text-align: center;
  font-weight: 500;
  font-size: 2rem !important;
  line-height: 1px !important;
  border: 0 !important;
  text-decoration: none;
  white-space: nowrap;
  max-width: 0!important;
  min-width: 40px !important;
  min-height: 40px !important;
  box-shadow: 0 0 0 0 rgba(227, 219, 209, 0.5);
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.policy-content-section .owl-theme .owl-nav {
    height: 50px;
    text-align: right !important;
    margin-top: 50px !important;
    margin-right: 0 !important;
}
.policy-content-section .owl-carousel .owl-nav button.owl-prev span,
.owl-carousel .owl-nav button.owl-next span {
 margin-bottom: 7px !important;
 display: block;
}
